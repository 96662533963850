import React, { useEffect, useState } from "react";
import { Button } from "../../UIKit/Button";
import { Contact } from "./Contact";
import { ContactSummary } from "../Sales/ContactSummary";
import {
  ResourceSelector,
  ResourceSelectorProps,
} from "../../UIKit/ResourceSelector";
import { v4 } from "uuid";

interface ContactSelectorProps extends ResourceSelectorProps<Contact> {
  headerSuggestions?: Contact[];
}

export function ContactSelector(props: ContactSelectorProps) {
  const [currentQuery, setCurrentQuery] = useState("");
  const [headerSuggestions, setHeaderSuggestions] = useState<Contact[]>([]);

  const minQueryLength =
    typeof props.minQueryLength === "number" ? props.minQueryLength : 3;

  function renderContact(c: Contact) {
    return (
      <div className="p-2 flex flex-row items-center">
        <div className="mr-4">
          <ContactSummary contact={c} />
        </div>
        <Button
          label="&#10003; sélectionner"
          onClick={() => {}}
          size="small"
          type="secondary"
        />
      </div>
    );
  }
  useEffect(() => {
    if (props.headerSuggestions !== undefined)
      setHeaderSuggestions(props.headerSuggestions);
  }, [props.headerSuggestions]);

  const createContact = (lastname: string) => {
    const contact = { id: v4(), isNew: true, lastname: lastname };
    props.onResourceChoose(contact, lastname);
  };
  const allowCreation = currentQuery.length >= minQueryLength;
  return (
    <ResourceSelector<Contact>
      resourceCacheKey="customer::search"
      onSearch={setCurrentQuery}
      placeholder={props.placeholder}
      label={props.label}
      alwaysDisplayHeader={true}
      header={{
        onSelect: (query) => createContact(query),
        node: (allowCreation || headerSuggestions.length) > 0 && (
          <div className="p-2 flex flex-row">
            {allowCreation && (
              <div className="mr-2">
                <Button
                  size="small"
                  onClick={() => createContact(currentQuery)}
                  label="&#10010; créer un nouveau client"
                />
              </div>
            )}
            {headerSuggestions.length > 0 &&
              headerSuggestions.map(
                (c) =>
                  c.lastname !== "" && (
                    <div className="mr-2" key={c.id}>
                      <Button
                        size="small"
                        onClick={() => props.onResourceChoose(c, currentQuery)}
                        label={`${c.firstname || ""} ${c.lastname}`}
                        type="secondary"
                      />
                    </div>
                  )
              )}
          </div>
        ),
      }}
      renderResource={renderContact}
      onResourceChoose={props.onResourceChoose}
      searchEngine={props.searchEngine}
    />
  );
}
