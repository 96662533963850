import {
  PAYMENT_METHOD_OTHER,
  PAYMENT_METHOD_UNDEFINED,
  paymentMethodForge,
  paymentMethods,
  PaymentTerm,
  paymentTermForge,
} from "./PaymentMethod";
import {
  Money,
  MONEY_ZERO,
  moneyAdd,
  moneyEquals,
  moneyForge,
  moneyFormat,
  moneySub,
} from "../utilities/Money";
import { InputField } from "../../UIKit/form/Input";
import { SelectField } from "../../UIKit/form/Select";
import { Button } from "../../UIKit/Button";
import React from "react";
import { validableFormWidgetProps } from "../../UIKit/form/forms";

export interface PaymentMethodSelectorProps extends validableFormWidgetProps {
  terms: PaymentTerm[];
  onPaymentTermsChange: (terms: PaymentTerm[]) => void;
  amount: Money;
}

export function PaymentMethodSelector(props: PaymentMethodSelectorProps) {
  function addTerm(amount: Money) {
    const newTerms = [
      ...props.terms,
      paymentTermForge(PAYMENT_METHOD_UNDEFINED, amount),
    ];
    props.onPaymentTermsChange(newTerms);
  }

  function removeTerm(termToRemove: PaymentTerm) {
    const paymentTerms = props.terms.filter((t) => {
      return t.id !== termToRemove.id;
    });

    if (paymentTerms.length === 1) {
      const newTerms = paymentTerms.map((t) => {
        return { ...t, amount: props.amount };
      });
      props.onPaymentTermsChange(newTerms);
    } else {
      props.onPaymentTermsChange(paymentTerms);
    }
  }
  function updateTerm(updatedTerm: PaymentTerm) {
    const newTerms = props.terms.map((t) => {
      if (t.id === updatedTerm.id) {
        return updatedTerm;
      }
      return t;
    });
    props.onPaymentTermsChange(newTerms);
  }

  return (
    <div>
      <div className="divide-y divide-gray-500 divide-dotted">
        {props.terms.map((t) => (
          <PaymentTermWidgetUI
            term={t}
            key={t.id}
            isValid={
              t.amount.amount > 0 &&
              t.paymentMethod !== PAYMENT_METHOD_UNDEFINED
            }
            onUpdate={updateTerm}
            onRemove={removeTerm}
            isRemovable={props.terms.length > 1}
            awaitsValidation={props.awaitsValidation}
          />
        ))}
      </div>
      <div className="flex-grow mt-2 ">
        <Remaining
          terms={props.terms}
          amount={props.amount}
          onAddTerm={addTerm}
        />
      </div>
    </div>
  );
}

function Remaining(props: {
  terms: PaymentTerm[];
  amount: Money;
  onAddTerm: (amount: Money) => void;
}) {
  function totalTermsAmount(terms: PaymentTerm[]) {
    return terms.reduce<Money>((acc, current) => {
      return moneyAdd(acc, current.amount);
    }, MONEY_ZERO);
  }
  const totalTerms = totalTermsAmount(props.terms);
  const remaining = moneySub(totalTerms, props.amount);

  return (
    <>
      {!moneyEquals(remaining, MONEY_ZERO) && (
        <span className="mr-4 text-red-500 font-bold">
          {remaining.amount < 0 && "manque "}
          {moneyFormat(remaining)}
          {remaining.amount > 0 && " de trop"}
        </span>
      )}
      <Button
        label="ajouter un mode de règlement"
        disabled={props.amount.amount === 0}
        size="tiny"
        onClick={() =>
          props.onAddTerm(
            remaining.amount < 0 ? moneySub(MONEY_ZERO, remaining) : MONEY_ZERO
          )
        }
        type="secondary"
      />
    </>
  );
}

interface PaymentTermWidgetUIProps extends validableFormWidgetProps {
  isRemovable: boolean;
  onRemove: (term: PaymentTerm) => void;
  onUpdate: (term: PaymentTerm) => void;
  term: PaymentTerm;
}

export function PaymentTermWidgetUI(props: PaymentTermWidgetUIProps) {
  return (
    <div className="flex flex-row pb-1  flex-grow items-center justify-between hover:bg-gray-300">
      <div className="flex flex-row justify-start items-end flex-grow">
        <InputField
          required={true}
          awaitsValidation={props.awaitsValidation}
          validationMessage="doit être > à 0"
          name={""}
          isValid={props.term.amount.amount > 0}
          suffix="€"
          onChange={(e) => {
            props.onUpdate({
              ...props.term,
              amount: moneyForge(e.target.value * 100),
            });
          }}
          value={(props.term.amount.amount / 100).toString()}
        />
        <span className="mx-2 self-end mb-2">en</span>
        <div className=" mr-2">
          <SelectField
            placeholder={"Choisissez..."}
            required={true}
            isValid={props.term.paymentMethod !== PAYMENT_METHOD_UNDEFINED}
            awaitsValidation={props.awaitsValidation}
            validationMessage={"Choisissez"}
            options={paymentMethods.map((m) => {
              return { value: m.method, label: m.label };
            })}
            value={props.term.paymentMethod.method}
            onChange={(m) => {
              props.onUpdate({
                ...props.term,
                paymentMethod: paymentMethodForge(m),
              });
            }}
          />
        </div>
        <div className=" mr-2">
          <InputField
            onChange={(r) => {
              props.onUpdate({
                ...props.term,
                reference: r.target.value,
              });
            }}
            prefix="Réf."
            name="reference"
            required={props.term.paymentMethod === PAYMENT_METHOD_OTHER}
            isValid={
              props.term.paymentMethod !== PAYMENT_METHOD_OTHER ||
              (!!props.term.reference &&
                props.term.paymentMethod === PAYMENT_METHOD_OTHER)
            }
            validationMessage="obligatoire"
            awaitsValidation={props.awaitsValidation}
            placeholder={"num. chèque, type de titre, etc."}
          />
        </div>
      </div>
      {props.isRemovable && (
        <div className="flex-shrink">
          <Button
            label="&#215;&nbsp;supprimer"
            size="tiny"
            onClick={() => props.onRemove(props.term)}
            type="secondary"
          />
        </div>
      )}
    </div>
  );
}
