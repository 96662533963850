import DineroFactory from "dinero.js";
import Dinero from "dinero.js";

Dinero.defaultCurrency = "EUR";
Dinero.globalLocale = "fr-FR";
Dinero.globalFormat = "$0,0.00";

export type Money = Dinero.DineroObject;
export const MONEY_ZERO: Money = DineroFactory({ amount: 0 }).toObject();

export function moneyForge(amount: number): Money {
  return DineroFactory({ amount: amount }).toObject();
}
export function moneyAdd(m1: Money, m2: Money) {
  return DineroFactory(m1).add(DineroFactory(m2)).toObject();
}
export function moneySub(m1: Money, m2: Money) {
  return DineroFactory(m1).subtract(DineroFactory(m2)).toObject();
}
export const moneyFormat = (m: Money) => DineroFactory(m).toFormat();

export function moneyEquals(m1: Money, m2: Money) {
  return (
    m1.amount === m2.amount &&
    m1.currency === m2.currency &&
    m1.precision === m2.precision
  );
}
