import { SpinnerLoading } from "./SpinnerLoading";
import React from "@storybook/react";

export function Chargement(props: { label?: string }) {
  return (
    <div className="flex flex-row items-center ">
      <SpinnerLoading />
      <span className="ml-2 text-gray-500">
        {props.label || "recherche en cours..."}
      </span>
    </div>
  );
}
