import {
  ContactsDirectoryAPI,
  ContactsDirectoryStats,
} from "../Contacts/ContactsDirectoryAPI";
import { Contact, ContactId } from "../Contacts/Contact";
import { FullTextSearchEngine } from "../../UIKit/ResourceSelector";
import { ReactNode } from "react";

export interface ContactsDirectoryI extends FullTextSearchEngine<Contact> {
  getOneById: (id: ContactId) => Promise<Contact | undefined>;
  findAll: () => Promise<Contact[]>;
  search: (q: string) => Promise<Contact[]>;
  add: (customer: Contact) => void;
  render: (item: Contact) => ReactNode;
  getStats: () => Promise<ContactsDirectoryStats>;
  findPaginated: (
    firstIdx: number,
    contactsPerPage: number
  ) => Promise<Contact[]>;
}

export const customersDirectory = ContactsDirectoryAPI;
