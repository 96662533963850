import { Money } from "../../utilities/Money";
import { DateTime } from "luxon";
import { Contact } from "../../Contacts/Contact";

export interface Product {
  label: string;
  id: string;
  unitPrice: Money;
  belongsToUser?: boolean;
}

export type ProductId = string;

export interface ProductData extends Product {}

export interface ProductRecipient extends Contact {}

export function contactIsValidAsRecipient(recipient: Contact): boolean {
  return (
    recipient.gender !== undefined &&
    recipient.birthdate !== undefined &&
    recipient.birthdate < DateTime.local() &&
    recipient.firstname !== undefined &&
    recipient.firstname.length > 0 &&
    recipient.lastname !== undefined &&
    recipient.lastname.length > 0
  );
}
