import { Redirect, Route } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import React from "react";
import { useAuth } from "./auth";

export function LoginPage() {
  let auth = useAuth();
  if (auth.user) {
    return (
      <Route>
        <Redirect to={{ pathname: "/" }} />
      </Route>
    );
  }

  return (
    <div className="container mx-auto flex flex-col min-h-screen items-center justify-center">
      <LoginForm onSubmit={auth.authenticate} />
    </div>
  );
}
