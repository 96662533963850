import {
  Autocomplete,
  AutocompleteHeader,
  AutocompleteResult,
} from "./Autocomplete";
import { UI_Size } from "./definitions";
import React, { ReactNode, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { InputLabel } from "./form/InputLabel";

export interface FullTextSearchEngine<T> {
  search: (searchQuery: string) => Promise<T[]>;
}

export interface ResourceSelectorProps<T> {
  onResourceChoose: (resource: T, currentQuery: string) => void;
  resourceCacheKey: string;
  renderResource?: (resource: T) => ReactNode;
  searchEngine: FullTextSearchEngine<T>;
  header?: AutocompleteHeader;
  minQueryLength?: number;
  alwaysDisplayHeader?: boolean;
  placeholder?: string;
  validationMessage?: string;
  isValid?: boolean;
  onSearch?: (query: string) => void;
  label?: string;
  size?: UI_Size;
}

export function ResourceSelector<T>(props: ResourceSelectorProps<T>) {
  const minQueryLength =
    typeof props.minQueryLength === "number" ? props.minQueryLength : 3;

  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState<AutocompleteResult[]>([]);
  const { isFetching, data } = useQuery(
    props.resourceCacheKey + "::" + searchQuery,
    () => {
      if (searchQuery.length < minQueryLength) {
        return Promise.resolve(undefined);
      }
      return props.searchEngine.search(searchQuery);
    },
    { staleTime: 5000 }
  );
  useEffect(() => {
    if (data) {
      setResults(
        data?.map((c) => {
          return {
            item: c,
            node: props.renderResource ? props.renderResource(c) : <>{c}</>,
          };
        })
      );
    }
  }, [data]);

  function onSearch(q: string) {
    setSearchQuery(q);
    if (props.onSearch) {
      props.onSearch(q);
    }
  }

  function onResourceChoose(item: T) {
    props.onResourceChoose(item, searchQuery);
  }

  return (
    <div>
      {!props.isValid && (
        <InputLabel
          label={props.label || "Ressource"}
          size="small"
          awaitsValidation={false}
          required={true}
          validationMessage={props.validationMessage || "choisissez"}
        />
      )}
      <Autocomplete
        size={props.size}
        placeholder={props.placeholder || "rechercher..."}
        onSearch={onSearch}
        hint={
          <div className="p-2 text-gray-400">ajoutez quelques lettres...</div>
        }
        waitsResultsUpdate={isFetching}
        alwaysDisplayHeader={props.alwaysDisplayHeader}
        onChooseResult={onResourceChoose}
        results={results}
        minQueryLength={minQueryLength}
        header={props.header}
      />
    </div>
  );
}
