import { Contact, contactLabel } from "../../Contacts/Contact";
import { Money, MONEY_ZERO, moneyAdd } from "../../utilities/Money";
import { contactIsValidAsRecipient, Product } from "../Catalogue/product";
import { v4 } from "uuid";
import {
  Cart,
  CartItem,
  CartItemId,
  CartServiceI,
  mergeContactData,
  Purchase,
} from "./Cart";

export class CartService implements CartServiceI {
  addProductTo = addProductTo;
  affectRecipientTo = affectRecipientToItem;
  assignContactTo = assignContactToItem;
  cartIsValid = (cart: Cart) => {
    return cart.items.reduce<boolean>((previous, item) => {
      return previous && this.cartItemIsValid(item);
    }, true);
  };
  cartItemIsCustomizable = cartItemIsCustomizable;
  cartItemIsValid = cartItemIsValid;
  extractPurchasesFrom = getPurchasesFrom;
  isFirstContactOccurenceInCart = isFirstRecipientOccurenceInCart;
  contactIsRecipientInCart = isRecipient;
  getAllRecipientsIn = recipientsInCart;
  removeProductFrom = removeProductFrom;
  totalPrice = totalCartPrice;
  unsetContactFrom = unsetContactFromItem;
}

function cartItemIsValid(i: CartItem): boolean {
  if (i.product.belongsToUser) {
    return i.recipient !== undefined && contactIsValidAsRecipient(i.recipient);
  }
  return true;
}

function cartItemIsCustomizable(i: CartItem): boolean {
  return !!i.product.belongsToUser;
}

function removeProductFrom(cart: Cart, id: CartItemId): Cart {
  return {
    ...cart,
    items: cart.items.filter((item) => {
      return item.id !== id;
    }),
  };
}

function assignContactToItem(cart: Cart, c: Contact, item: CartItem): Cart {
  return {
    ...cart,
    items: cart.items.map((i) => {
      if (i.id === item.id) {
        return { ...i, recipient: c };
      }
      return i;
    }),
  };
}

function affectRecipientToItem(cart: Cart, recipient: Contact, item: CartItem) {
  return {
    ...cart,
    items: cart.items.map((i) => {
      if (i.id === item.id) {
        return { ...i, recipient: recipient };
      }
      return i;
    }),
  };
}

export function updateRecipientData(cart: Cart, recipient: Contact) {
  return {
    ...cart,
    items: cart.items.map((i) => {
      if (i.recipient?.id === recipient.id) {
        return { ...i, recipient: mergeContactData(recipient, i.recipient) };
      }
      return i;
    }),
  };
}

function unsetContactFromItem(cart: Cart, item: CartItem) {
  return {
    ...cart,
    items: cart.items.map((i) => {
      if (i.id === item.id) {
        return { ...i, recipient: undefined };
      }
      return i;
    }),
  };
}

function isRecipient(contact: Contact, cart: Cart) {
  return cart.items.reduce<boolean>((acc, current) => {
    return acc || current.recipient?.id === contact.id;
  }, false);
}

function getPurchasesFrom(cart: Cart): Purchase[] {
  return cart.items.map((i) => {
    return {
      customization: i.recipient
        ? {
            recipientId: i.recipient.id,
            recipientName: contactLabel(i.recipient),
          }
        : undefined,
      id: i.id,
      label: i.product.label,
      productId: i.product.id,
      quantity: 1,
      unitPrice: i.product.unitPrice,
    };
  });
}

function totalCartPrice(cart: Cart): Money {
  return cart.items.reduce<Money>((total, p) => {
    return moneyAdd(total, p.product.unitPrice);
  }, MONEY_ZERO);
}

export function addProductTo(p: Product, cart: Cart): Cart {
  return { ...cart, items: [...cart.items, { id: v4(), product: p }] };
}

function recipientsInCart(cart: Cart) {
  return cart.items.reduce<Contact[]>((acc, current) => {
    if (current.recipient !== undefined) {
      if (
        acc.find((c) => {
          return c.id === current.recipient?.id;
        }) !== undefined
      ) {
        return acc;
      }
      acc.push(current.recipient);
    }
    return acc;
  }, []);
}

function isFirstRecipientOccurenceInCart(
  cartItem: CartItem,
  cart: Cart
): boolean {
  const recipient = cartItem.recipient;
  if (recipient && cart.items.length > 0) {
    const itemsConcerningRecipient = cart.items.filter((i) => {
      return i.recipient?.id === recipient.id;
    });
    return itemsConcerningRecipient[0].id === cartItem.id;
  }
  return cart.items.length !== 0;
}
