import React, { HTMLAttributes, useEffect, useState } from "react";
import { UI_Size } from "../definitions";
import { InputLabel } from "./InputLabel";
import { Gender } from "../../modules/utilities/types";

interface InputGenderProps extends HTMLAttributes<HTMLElement> {
  size?: UI_Size;
  required?: boolean;
  isValid?: boolean;
  awaitsValidation?: boolean;
  label: string;
  disabled?: boolean;
  name?: string;
  value?: Gender;
  validationMessage?: string;
  onChoose: (g: Gender) => void;
}

export function InputGender(props: InputGenderProps) {
  const [value, setValue] = useState<Gender>(props.value || "none");
  function onSelect(e: any) {
    setValue(e.target.value);
    props.onChoose(e.target.value);
  }
  useEffect(() => {
    setValue(props.value || "none");
  }, [props.value]);
  return (
    <div className={props.className}>
      <InputLabel
        label={props.label}
        size={props.size}
        required={props.required}
        isValid={props.isValid}
        validationMessage={props.validationMessage}
        awaitsValidation={props.awaitsValidation}
      />
      <div className="text-sm mt-2">
        <label className="mr-2">
          <input
            type="radio"
            name={props.name + "_gender"}
            value="none"
            disabled={props.disabled}
            checked={value === "none"}
            onChange={onSelect}
            className={props.disabled ? "opacity-25" : ""}
          />{" "}
          neutre
        </label>
        <label className="mr-2">
          <input
            type="radio"
            name={props.name + "_gender"}
            value="mrs"
            disabled={props.disabled}
            checked={value === "mrs"}
            className={props.disabled ? "opacity-25" : ""}
            onChange={onSelect}
          />{" "}
          Mme
        </label>
        <label>
          <input
            type="radio"
            name={props.name + "_gender"}
            value="mr"
            disabled={props.disabled}
            checked={value === "mr"}
            className={props.disabled ? "opacity-25" : ""}
            onChange={onSelect}
          />{" "}
          M.
        </label>
      </div>
    </div>
  );
}
