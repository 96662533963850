import React from "react";
import { mandatoryFieldLabel, validableFormWidgetProps } from "./forms";
import { UI_Size } from "../definitions";
import { InputLabel } from "./InputLabel";

interface Choice {
  value: string;
  label?: string;
}

interface SelectProps extends validableFormWidgetProps {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  label?: string;
  size?: UI_Size;
  placeholder?: string;
  options: Choice[];
  onChange: (value: string) => void;
}

export function Select(props: SelectProps) {
  function onChoiceChange(e: any) {
    props.onChange(e);
  }

  return (
    <div>
      <InputLabel
        required={props.required}
        label={props.label || ""}
        size={props.size}
        awaitsValidation={props.awaitsValidation}
        isValid={props.isValid}
        validationMessage={props.validationMessage || mandatoryFieldLabel}
      />
      <SelectField
        options={props.options}
        onChange={onChoiceChange}
        id={props.id}
        required={props.required}
        name={props.name}
        size={props.size}
        placeholder={props.placeholder}
        value={props.value}
      />
    </div>
  );
}

interface SelectFieldProps extends validableFormWidgetProps {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  size?: UI_Size;
  placeholder?: string;
  options: Choice[];
  onChange: (value: string) => void;
}
export function SelectField(props: SelectFieldProps) {
  const invalidClassNames =
    props.awaitsValidation || props.isValid
      ? " focus:ring-indigo-500 focus:border-indigo-500"
      : " ring-red-500 border-red-500 bg-red-50";
  return (
    <select
      id={props.id || ""}
      name={props.name || ""}
      value={props.value}
      required={props.required}
      onChange={(e) => props.onChange(e.target.value)}
      className={
        "mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none " +
        invalidClassNames
      }
    >
      {props.placeholder && (
        <option value={undefined}>{props.placeholder}</option>
      )}
      {props.options.map((o, k) => (
        <option value={o.value} key={k}>
          {o.label || o.value}
        </option>
      ))}
    </select>
  );
}
