import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { salesBook } from "./Sales";
import {
  Contact,
  contactIsValidAsCustomer,
  EMPTY_CONTACT,
} from "../Contacts/Contact";
import {
  PAYMENT_TERM_UNDEFINED,
  PaymentTerm,
  paymentTermsAreValid,
} from "./PaymentMethod";
import Routes from "./Routes";
import { ProductSelector } from "./Catalogue/ProductSelector";
import { FormSection } from "../../UIKit/form/Section";
import { CustomerEditForm } from "./CustomerEditForm";
import { InputDate } from "../../UIKit/form/InputDate";
import { Button } from "../../UIKit/Button";
import { CatalogueI } from "./Catalogue/Catalogue";
import { ContactSelector } from "../Contacts/ContactSelector";
import { Product } from "./Catalogue/product";
import { ContactSummary } from "./ContactSummary";
import { Cart, CartServiceI, EMPTY_CART } from "./Cart/Cart";
import { CartUI } from "./Cart/CartUI";
import { customersDirectory } from "./CustomersDirectory";
import { InputLabel } from "../../UIKit/form/InputLabel";
import { PaymentMethodSelector } from "./PaymentMethodSelector";
import { addProductTo, updateRecipientData } from "./Cart/CartService";

interface SalesFormProps {
  catalogue: CatalogueI;
  cartService: CartServiceI;
}

export function SalesForm(props: SalesFormProps) {
  const history = useHistory();

  const [awaitsValidation, setFormAwaitsSubmission] = useState(true);
  const [cart, setCart] = useState<Cart>(EMPTY_CART);
  const [date, setDate] = useState<DateTime>(DateTime.local());
  const [customer, setCustomer] = useState<Contact>(EMPTY_CONTACT);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [paymentTerms, setPaymentTerms] = useState<PaymentTerm[]>([
    PAYMENT_TERM_UNDEFINED,
  ]);

  const cartPrice = props.cartService.totalPrice(cart);

  function formIsValid() {
    if (!cart.items.length) {
      return false;
    }
    if (!contactIsValidAsCustomer(customer)) {
      return false;
    }
    if (!date.isValid) {
      return false;
    }
    if (!paymentTermsAreValid(paymentTerms, cartPrice)) {
      return false;
    }
    if (!props.cartService.cartIsValid(cart)) {
      return false;
    }
    return true;
  }

  function onSubmit() {
    setFormAwaitsSubmission(false);
    if (formIsValid()) {
      if (!customer.id) {
        customersDirectory.add(customer);
      }
      salesBook.add({
        amount: cartPrice,
        customer: customer,
        date: DateTime.local(),
        paymentTerms: paymentTerms,
        purchases: props.cartService.extractPurchasesFrom(cart),
      });
      setFormAwaitsSubmission(true);
      history.push(Routes.sales);
    }
  }

  function onProductSelectorChange(p: Product) {
    formIsValid();
    setCart(addProductTo(p, cart));
  }

  function onCustomerChange(c: Contact) {
    setCustomer(c);
  }

  function onDateChange(d: DateTime) {
    setDate(d);
  }

  useEffect(() => {
    if (props.cartService.contactIsRecipientInCart(customer, cart)) {
      setContacts(props.cartService.getAllRecipientsIn(cart));
    } else {
      setContacts([...props.cartService.getAllRecipientsIn(cart), customer]);
    }
    if (paymentTerms.length === 1) {
      setPaymentTerms(
        paymentTerms.map((t) => {
          return { ...t, amount: cartPrice };
        })
      );
    }
  }, [cart.items]);

  useEffect(() => {
    setCart(updateRecipientData(cart, customer));
  }, [customer]);

  return (
    <div className="">
      <h1 className="text-2xl font-bold py-4 px-2 sm:px-0">
        Saisir une nouvelle vente
      </h1>
      <FormSection
        legend="Le ou les produits achetés. Il peut y en avoir plusieurs."
        title="Panier"
      >
        <fieldset>
          <ProductSelector
            resourceCacheKey="product::search"
            onResourceChoose={onProductSelectorChange}
            isValid={awaitsValidation || cart.items.length > 0}
            awaitsValidation={awaitsValidation}
            validationMessage={""}
            label={"Produits"}
            searchEngine={props.catalogue}
            onProductSelect={onProductSelectorChange}
          />
          <div className="my-2">
            <CartUI
              cart={cart}
              awaitsValidation={awaitsValidation}
              onRemove={(item) =>
                setCart(props.cartService.removeProductFrom(cart, item))
              }
              onRecipientAssign={(item, recipient) => {
                setCart(
                  props.cartService.assignContactTo(cart, recipient, item)
                );
              }}
              onRecipientUnset={(item) => {
                setCart(props.cartService.unsetContactFrom(cart, item));
              }}
              onNewRecipientData={(item, recipient) => {
                if (recipient.id === customer.id) {
                  setCustomer(recipient);
                }
                setCart(updateRecipientData(cart, recipient));
              }}
              headerSuggestions={contacts}
            />
          </div>
        </fieldset>
      </FormSection>
      <FormSection
        title="Client"
        legend="La personne qui achète. ...Ça n'est pas forcément la bénéficiaire des prestations."
      >
        <ContactSelector
          resourceCacheKey="customer::search"
          headerSuggestions={contacts}
          placeholder="recherchez un client existant..."
          label="Client"
          searchEngine={customersDirectory}
          isValid={
            awaitsValidation ||
            contactIsValidAsCustomer(customer) ||
            existingCustomer
          }
          onResourceChoose={(c) => {
            if (contactIsValidAsCustomer(c)) setExistingCustomer(true);
            onCustomerChange(c);
          }}
        />
        {contactIsValidAsCustomer(customer) && existingCustomer && false ? (
          <ContactSummary contact={customer} />
        ) : customer !== EMPTY_CONTACT ? (
          <CustomerEditForm
            onDataChange={(c) => {
              onCustomerChange(c);
              setExistingCustomer(false);
            }}
            disabledFields={
              props.cartService.contactIsRecipientInCart(customer, cart)
                ? ["gender", "firstname", "lastname", "birthdate"]
                : []
            }
            contact={customer}
            isValid={awaitsValidation || contactIsValidAsCustomer(customer)}
            awaitsValidation={awaitsValidation}
          />
        ) : (
          <></>
        )}
      </FormSection>{" "}
      <FormSection
        legend="La façon dont le client souhaite régler cette vente. Il peut y avoir plusieurs modes de paiement"
        title="Paiement"
      >
        <div className="col-span-3">
          <InputLabel
            required={true}
            label={"Modalités de règlement"}
            awaitsValidation={awaitsValidation}
            isValid={paymentTermsAreValid(paymentTerms, cartPrice)}
            validationMessage={"vérifiez les modalités"}
          />
          <PaymentMethodSelector
            terms={paymentTerms}
            awaitsValidation={awaitsValidation}
            isValid={awaitsValidation}
            onPaymentTermsChange={setPaymentTerms}
            amount={cartPrice}
          />
        </div>
        <InputDate
          onChange={onDateChange}
          date={date}
          name="date"
          awaitsValidation={awaitsValidation}
          isValid={date <= DateTime.local()}
          validationMessage={"Choisissez une date valide"}
          label="Date d'achat"
        />
      </FormSection>
      <FormSection legend="" title="" final={true}>
        <div className={"flex flex-row justify-left items-center"}>
          <Button
            label="&#10010; Ajouter cette vente"
            onClick={onSubmit}
            disabled={!formIsValid() && !awaitsValidation}
          />
          {!formIsValid() && !awaitsValidation && (
            <>
              <div className="ml-3 text-3xl">🤭</div>
              <div className={"ml-3 text-xs"}>
                Il y a quelques problèmes dans ce formulaire.
                <br />
                Regardez les messages ci-dessus.
              </div>
            </>
          )}
        </div>
      </FormSection>
    </div>
  );
}
