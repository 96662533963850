import React from "react";
import { validableFormWidgetProps } from "../../../UIKit/form/forms";
import { Product } from "./product";
import { Button } from "../../../UIKit/Button";
import { moneyFormat } from "../../utilities/Money";
import {
  ResourceSelector,
  ResourceSelectorProps,
} from "../../../UIKit/ResourceSelector";

interface ProductSelectorProps
  extends validableFormWidgetProps,
    ResourceSelectorProps<Product> {
  onProductSelect: (p: Product) => void;
}

export function ProductSelector(props: ProductSelectorProps) {
  function onProductSelect(p: Product): void {
    props.onProductSelect(p);
  }
  function renderProduct(p: Product) {
    return (
      <div className="p-2 flex flex-row items-center">
        <div>
          <p className="font-bold text-purple-500">{p.label}</p>
          <p className="">{moneyFormat(p.unitPrice)}</p>
        </div>
        <div className="ml-2">
          <Button
            size="small"
            onClick={() => {}}
            label="&#10010; Ajouter au panier"
          />
        </div>
      </div>
    );
  }
  return (
    <ResourceSelector
      resourceCacheKey="product::search"
      minQueryLength={0}
      label={props.label || "Produit"}
      onResourceChoose={onProductSelect}
      searchEngine={props.searchEngine}
      renderResource={renderProduct}
    />
  );
}
