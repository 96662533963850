import { Input, InputProps } from "./Input";
import React from "react";

export function emailIsValid(email?: string): boolean {
  return email !== undefined && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

interface InputEmailProps extends InputProps {
  onChange: (e: string) => void;
  email: string;
}

export function InputEmail(props: InputEmailProps) {
  function onChange(e: any) {
    const email = e.target.value;
    props.onChange(email);
  }

  return (
    <Input
      name={props.name}
      required={props.required}
      label={props.label}
      value={props.email}
      isValid={props.isValid || emailIsValid(props.email)}
      awaitsValidation={props.awaitsValidation}
      validationMessage={props.validationMessage}
      onChange={onChange}
    />
  );
}
