import { moneyFormat } from "../../utilities/Money";
import React from "react";
import { Button } from "../../../UIKit/Button";
import { Cart, CartItem, CartItemId } from "./Cart";
import { ContactSelector } from "../../Contacts/ContactSelector";
import { Contact } from "../../Contacts/Contact";
import { Input } from "../../../UIKit/form/Input";
import { customersDirectory } from "../CustomersDirectory";
import { InputGender } from "../../../UIKit/form/InputGender";
import { DateTime } from "luxon";
import { ProductRecipient } from "../Catalogue/product";
import { InputDate } from "../../../UIKit/form/InputDate";
import { CartService } from "./CartService";

const cartService = new CartService();

export function CartUI(props: {
  cart: Cart;
  awaitsValidation: boolean;
  headerSuggestions?: Contact[];
  onRemove: (cartItemId: CartItemId) => void;
  onRecipientAssign: (item: CartItem, c: Contact) => void;
  onNewRecipientData: (item: CartItem, r: ProductRecipient) => void;
  onRecipientUnset: (item: CartItem) => void;
}) {
  if (props.cart.items.length === 0) {
    return <></>;
  }

  return (
    <div className=" divide-y">
      {props.cart.items.map((item) => {
        const recipientIsEditable = cartService.isFirstContactOccurenceInCart(
          item,
          props.cart
        );
        return (
          <CartItemUI
            headerSuggestions={props.headerSuggestions}
            key={item.id}
            cartItem={item}
            recipientIsEditable={recipientIsEditable}
            awaitsValidation={props.awaitsValidation}
            onRemove={() => props.onRemove(item.id)}
            onRecipientAssign={(c) => {
              props.onRecipientAssign(item, c);
            }}
            onRecipientUnset={() => {
              props.onRecipientUnset(item);
            }}
            onNewRecipientData={(recipient) => {
              props.onNewRecipientData(item, recipient);
            }}
          />
        );
      })}
      <div>Total : {moneyFormat(cartService.totalPrice(props.cart))}</div>
    </div>
  );
}

function CartItemUI(props: {
  headerSuggestions?: Contact[];
  cartItem: CartItem;
  awaitsValidation: boolean;
  recipientIsEditable: boolean;
  onRemove: (k: number) => void;
  onRecipientAssign: (c: Contact) => void;
  onRecipientUnset: (id: CartItemId) => void;
  onNewRecipientData: (r: ProductRecipient) => void;
}) {
  return (
    <div className="flex flex-row items-start p-2 hover:bg-gray-200">
      <div className="mr-2">&rarr;</div>
      <div className="grid grid-cols-3 gap-x-4 flex-grow">
        <div>
          <strong>{props.cartItem.product.label}</strong>
        </div>
        <div>{moneyFormat(props.cartItem.product.unitPrice)}</div>
        <div className="w-24">
          <Button
            label="&#215;&nbsp;supprimer"
            onClick={props.onRemove}
            size="tiny"
            type="secondary"
          />
        </div>
        <div className="col-span-3">
          {cartService.cartItemIsCustomizable(props.cartItem) && (
            <CartItemCustomization
              headerSuggestions={props.headerSuggestions}
              cartItem={props.cartItem}
              recipientIsEditable={props.recipientIsEditable}
              awaitsValidation={props.awaitsValidation}
              onRecipientAssign={props.onRecipientAssign}
              onRecipientUnset={props.onRecipientUnset}
              onNewRecipientData={props.onNewRecipientData}
            />
          )}
        </div>
      </div>
    </div>
  );
}
function CartItemCustomization(props: {
  cartItem: CartItem;
  headerSuggestions?: Contact[];
  awaitsValidation: boolean;
  recipientIsEditable: boolean;
  onRecipientAssign: (c: Contact) => void;
  onRecipientUnset: (id: CartItemId) => void;
  onNewRecipientData: (r: ProductRecipient) => void;
}) {
  if (!props.recipientIsEditable) {
    return (
      <CustomizationData
        cartItem={props.cartItem}
        onRecipientUnset={props.onRecipientUnset}
      />
    );
  }
  return (
    <div>
      <CartItemCustomizer
        headerSuggestions={props.headerSuggestions}
        cartItem={props.cartItem}
        onRecipientUnset={props.onRecipientUnset}
        onRecipientAssign={props.onRecipientAssign}
        onRecipientDataChange={props.onNewRecipientData}
        awaitsValidation={props.awaitsValidation}
      />
    </div>
  );
}

function CustomizationData(props: {
  cartItem: CartItem;
  onRecipientUnset: (id: CartItemId) => void;
}) {
  return (
    <span className="text-sm">
      {`pour ${props.cartItem.recipient?.gender} ${props.cartItem.recipient?.firstname} ${props.cartItem.recipient?.lastname} `}
      <button
        className="text-gray-500 cursor-pointer"
        onClick={() => props.onRecipientUnset(props.cartItem.id)}
      >
        [changer]
      </button>
    </span>
  );
}

function CartItemCustomizer(props: {
  cartItem: CartItem;
  headerSuggestions?: Contact[];
  awaitsValidation: boolean;
  onRecipientAssign: (c: Contact) => void;
  onRecipientUnset: (id: CartItemId) => void;
  onRecipientDataChange: (r: ProductRecipient) => void;
}) {
  function onContactChoose(c: Contact) {
    props.onRecipientAssign(c);
  }

  if (props.cartItem.recipient) {
    return (
      <div className="flex-row flex items-start flex-wrap">
        <InputGender
          className="flex-shrink mr-1"
          size="small"
          label="Genre"
          name={Math.random().toString()}
          value={props.cartItem.recipient?.gender}
          isValid={props.cartItem.recipient.gender !== undefined}
          validationMessage="requis"
          awaitsValidation={props.awaitsValidation}
          onChoose={(g) =>
            props.onRecipientDataChange({
              ...props.cartItem.recipient!,
              gender: g,
            })
          }
        />
        <Input
          className="flex-shrink mr-1"
          name="lastname"
          value={props.cartItem.recipient.lastname}
          size="small"
          isValid={
            props.cartItem.recipient.lastname !== undefined &&
            props.cartItem.recipient.lastname !== ""
          }
          label="Nom"
          onChange={(e) => {
            props.onRecipientDataChange({
              ...props.cartItem.recipient!,
              lastname: e.target.value,
            });
          }}
          validationMessage="requis"
          awaitsValidation={props.awaitsValidation}
        />
        <Input
          className="flex-shrink mr-1"
          name="firstname"
          size="small"
          label="Prénom"
          defaultValue={props.cartItem.recipient.firstname}
          value={props.cartItem.recipient.firstname}
          isValid={
            props.cartItem.recipient.firstname !== undefined &&
            props.cartItem.recipient.firstname !== ""
          }
          onChange={(e) => {
            props.onRecipientDataChange({
              ...props.cartItem.recipient!,
              firstname: e.target.value,
            });
          }}
          validationMessage="requis"
          awaitsValidation={props.awaitsValidation}
        />
        <InputDate
          onChange={(d) => {
            props.onRecipientDataChange({
              ...props.cartItem.recipient!,
              birthdate: d,
            });
          }}
          date={props.cartItem.recipient.birthdate}
          name="birthdate"
          label="Date de naiss."
          size="small"
          isValid={
            props.cartItem.recipient.birthdate !== undefined &&
            props.cartItem.recipient.birthdate < DateTime.local()
          }
          validationMessage="requis"
          awaitsValidation={props.awaitsValidation}
          className="flex-shrink mr-1"
        />
        <button
          className="text-sm text-gray-500 cursor-pointer flex-shrink self-end"
          onClick={() => props.onRecipientUnset(props.cartItem.id)}
        >
          [changer de bénéficiaire]
        </button>
      </div>
    );
  }
  return (
    <ContactSelector
      searchEngine={customersDirectory}
      size="small"
      resourceCacheKey="customer::"
      isValid={false}
      onResourceChoose={onContactChoose}
      validationMessage="Choisissez un contact"
      placeholder="rechercher un contact existant..."
      label="Bénéficiaire"
      headerSuggestions={props.headerSuggestions}
      alwaysDisplayHeader={true}
    />
  );
}
