import { Sale, SalesBookI } from "./Sales";
import { DateTime } from "luxon";

export const MirageSalesBook: SalesBookI = {
  getAll: () => {
    return fetch("/api/sales/")
      .then((response) => response.text())
      .then((salesData) => {
        return JSON.parse(salesData, (keyName, value) => {
          return keyName === "date" ? DateTime.fromISO(value) : value;
        });
      });
  },
  add: (s: Sale) => {
    fetch("/api/sales/add", {
      method: "POST",
      body: JSON.stringify(s),
    })
      .then((response) => response.json())
      .then((r) => r);
  },
};
