import React from "react";
import { DateTime } from "luxon";
import { Input, InputProps } from "./Input";

interface InputDateProps extends InputProps {
  onChange: (d: DateTime) => void;
  date?: DateTime;
}

export function InputDate(props: InputDateProps) {
  function onChange(e: any) {
    const d = DateTime.fromISO(e.target.value);
    props.onChange(d);
  }

  return (
    <Input
      size={props.size}
      className={props.className}
      disabled={props.disabled}
      name={props.name}
      label={props.label}
      type="date"
      isValid={props.isValid}
      validationMessage={props.validationMessage}
      awaitsValidation={props.awaitsValidation}
      value={props.date ? props.date.toISODate() : ""}
      onChange={onChange}
    />
  );
}
