import { Routes } from "../../app/Routes";

const base = Routes.contacts;

const routes = {
  contacts: base,
  add: base + "/add",
};

export default routes;
