import { Contact } from "./modules/Contacts/Contact";
import { Sale } from "./modules/Sales/Sales";
import { createServer } from "miragejs";
import { Product } from "./modules/Sales/Catalogue/product";
import { moneyForge } from "./modules/utilities/Money";
import { ContactsDirectoryStats } from "./modules/Contacts/ContactsDirectoryAPI";

const products: Product[] = [
  {
    label: "Adhésion + de 18 ans",
    id: "adhesion_plus_18",
    unitPrice: moneyForge(4000),
    belongsToUser: true,
  },
  {
    label: "Adhésion - de 18 ans",
    id: "adhesion_moins_18",
    unitPrice: moneyForge(1000),
    belongsToUser: true,
  },
  {
    label: "Stage optimist débutant",
    id: "opti-deb",
    unitPrice: moneyForge(11500),
    belongsToUser: true,
  },
  {
    label: "Stage laser",
    id: "opti-deb",
    unitPrice: moneyForge(11500),
    belongsToUser: true,
  },
  {
    label: "Stage cata",
    id: "opti-deb",
    unitPrice: moneyForge(11500),
    belongsToUser: true,
  },
  {
    label: "Casquette - Taille unique",
    id: "casquette",
    unitPrice: moneyForge(1250),
  },
  {
    label: "Gobelet - Edition 2019",
    id: "gobelet-2019",
    unitPrice: moneyForge(100),
  },
  {
    label: "Gobelet - Edition 2020",
    id: "gobelet-2019",
    unitPrice: moneyForge(100),
  },
];

const customers: Contact[] = [
  {
    id: "1",
    email: "sylvain@ferlac.fr",
    firstname: "Sylvain",
    lastname: "Ferlac",
    phone: "0777262558",
  },
  {
    id: "2",
    email: "gd@gmail.com",
    firstname: "Gérard",
    lastname: "Durand",
    phone: "020304056",
  },
  {
    id: "3",
    email: "gd@gmail.com",
    firstname: "Martin",
    lastname: "Martin",
    phone: "020304056",
  },
  {
    id: "4",
    email: "gd@gmail.com",
    firstname: "Henry",
    lastname: "Dupond",
    phone: "020304056",
  },
  {
    id: "5",
    email: "ad@orange.fr",
    firstname: "Amédée",
    lastname: "Dupont",
    phone: "020304050",
  },
  {
    id: "6",
    email: "jd@gmail.com",
    firstname: "John",
    lastname: "Duportte",
    phone: "020203040",
  },
];

const sales: Sale[] = [];

export function makeServer({ environment = "test" } = {}) {
  return createServer({
    environment,

    /* FOR NOW WE DON'T KNOW HOW TO USE THIS

    models: {
      user: Model,
    },

    seeds(server) {
      server.create("user", { name: "Bob" })
      server.create("user", { name: "Alice" })
    },

    */

    routes() {
      this.namespace = "api";
      this.timing = 500;
      this.get("/search/contacts/", (schema, request) => {
        return customers.filter((c) => {
          return (
            c
              .lastname!.toLowerCase()
              .includes(request.queryParams.q.toLowerCase()) ||
            c
              .firstname!.toLowerCase()
              .includes(request.queryParams.q.toLowerCase())
          );
        });
      });
      this.get(
        "/stats/contacts",
        (schema, request): ContactsDirectoryStats => {
          return { totalContactsCount: customers.length };
        }
      );
      this.get("/contacts/:id", (schema, request) => {
        return (
          customers.find((c) => {
            return c.id === request.params.id;
          }) || []
        );
      });
      this.get("/contacts/", () => {
        return customers;
      });
      this.post("/contacts/", (schema, request) => {
        customers.push(JSON.parse(request.requestBody));
        return {};
      });

      this.get("/products/", () => {
        return products;
      });
      this.get("/products/:id", (schema, request) => {
        return (
          products.find((p) => {
            return p.id === request.params.id;
          }) || []
        );
      });
      this.get("/search/products/", (schema, request) => {
        return products.filter((p) => {
          return p.label
            .toLowerCase()
            .includes(request.queryParams.q.toLowerCase());
        });
      });

      this.get("/sales/", () => {
        return sales;
      });
      this.post("/sales/add", (schema, request) => {
        sales.push(JSON.parse(request.requestBody));
        return {};
      });
    },
  });
}
