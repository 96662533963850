import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Chargement } from "../../UIKit/Chargement";
import { Button } from "../../UIKit/Button";
import Routes from "./Routes";
import { ContactsDirectoryI } from "../Sales/CustomersDirectory";
import { Contact, contactLabel } from "./Contact";
import { Badge } from "../../UIKit/Badge";
import { PaginatedTable } from "../../UIKit/Table";
import { ContactsDirectoryStats } from "./ContactsDirectoryAPI";

interface ContactsListProps {
  contactsDirectory: ContactsDirectoryI;
}

export function ContactsList(props: ContactsListProps) {
  const contactsPerPage = 20;

  const history = useHistory();
  const [startingAt, setStartingAt] = useState<number>(1);
  const { isFetching: contactsAreFetching, data: contacts } = useQuery<
    Contact[]
  >(
    "contacts::all::page::" + startingAt,
    () => {
      return props.contactsDirectory.findPaginated(
        (startingAt - 1) * contactsPerPage + 1,
        contactsPerPage
      );
    },
    { keepPreviousData: true } // To allow a smooth pagination
  );
  const { data: stats } = useQuery<ContactsDirectoryStats>(
    "contacts::stats",
    () => {
      return props.contactsDirectory.getStats();
    }
  );

  function loadContactsSlice(toto: number) {
    setStartingAt(toto);
  }

  if (!contacts) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Chargement label="récupération des contacts..." />
      </div>
    );
  } else {
    return (
      <>
        {contacts.length === 0 ? (
          <div className="min-h-screen flex flex-col justify-center">
            <div className="text-center mb-2">
              <p>...pas de contact pour le moment 🤷‍</p>
              <p>Ça ne saurait durer !</p>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-between">
              <h2 className="text-xl font-bold">
                {contacts.length} contact{contacts.length > 1 && "s"}
              </h2>
              {contactsAreFetching && (
                <div className="items-center">
                  <Chargement label="vérification nouveaux contacts" />
                </div>
              )}
            </div>

            <PaginatedTable<Contact>
              onPageChange={loadContactsSlice}
              totalItemsCount={stats?.totalContactsCount || 0}
              itemsPerPage={contactsPerPage}
              data={contacts}
              page={startingAt}
              columns={[
                { label: "Nom", content: (c) => contactLabel(c) },
                {
                  label: "Badges",
                  content: (c) => (
                    <>
                      <Badge label="Adhérent" type="primary" flavor="light" />
                      <Badge label="Stagiaire" type="primary" flavor="light" />
                      <Badge label="Vala" type="primary" flavor="light" />
                    </>
                  ),
                },
                { label: "Role", content: "birthdate" },
                { label: "edit", content: (c) => <>edit</> },
              ]}
            />
          </div>
        )}
        <div className="text-center mb-2 mt-4">
          <div>
            <Button
              label="&#10010; Ajouter un contact"
              onClick={(e) => {
                history.push(Routes.add);
              }}
            />
          </div>
        </div>
      </>
    );
  }
}
