import React from "react";
import { useHistory } from "react-router-dom";
import Routes from "./Routes";
import { Button } from "../../UIKit/Button";
import { Sale, SalesBookI } from "./Sales";
import { useQuery } from "react-query";
import { Chargement } from "../../UIKit/Chargement";
import { Table } from "../../UIKit/Table";
import { contactLabel } from "../Contacts/Contact";
import { moneyFormat } from "../utilities/Money";
import { Badge } from "../../UIKit/Badge";
import { Purchase } from "./Cart/Cart";
import { PaymentTerm } from "./PaymentMethod";

export interface SalesListProps {
  salesBook: SalesBookI;
}

export function SalesList(props: SalesListProps) {
  const history = useHistory();
  const { isFetching, data } = useQuery<Sale[]>("sales::all", () => {
    return props.salesBook.getAll();
  });
  if (!data) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center">
        <Chargement label="récupération des ventes..." />
      </div>
    );
  } else {
    return (
      <div className="min-h-screen flex flex-col justify-center">
        {data.length === 0 ? (
          <div className="text-center mb-2">
            <p>...pas de ventes pour le moment 🤷‍</p>
            <p>Ça ne saurait durer !</p>
          </div>
        ) : (
          <div>
            <h2 className="text-xl font-bold">
              {data.length} vente{data.length > 1 && "s"}
            </h2>
            <Table<Sale>
              data={data}
              columns={[
                {
                  label: "Produits",
                  content: (s) => <PurchasesCell purchases={s.purchases} />,
                },
                {
                  label: "Paiements",
                  content: (s) => (
                    <PaymentTermsCell paymentTerms={s.paymentTerms} />
                  ),
                },
                {
                  label: "total",
                  content: (s) => (
                    <p className="font-black text-xl text-gray-400">
                      {moneyFormat(s.amount)}
                    </p>
                  ),
                },
                {
                  label: "client",
                  content: (s) => <span>{contactLabel(s.customer)}</span>,
                },
                {
                  label: "date",
                  content: (s) => s.date.toFormat("d LLL"),
                },
                {
                  label: "modifier",
                  content: (s) => (
                    <Button
                      onClick={() => {}}
                      label="modifier"
                      type="secondary"
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
        {isFetching && (
          <div className="items-center">
            <Chargement label="vérification nouvelles ventes" />
          </div>
        )}
        <div className="text-center mb-2">
          <div>
            <Button
              label="&#10010; Ajouter une vente"
              onClick={(e) => {
                history.push(Routes.add);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function PurchasesCell(props: { purchases: Purchase[] }) {
  return (
    <>
      {props.purchases.map((p) => (
        <div key={p.id}>
          <strong>{p.label}</strong>
          {p.customization
            ? " (pour " + p.customization.recipientName + ")"
            : ""}
        </div>
      ))}
    </>
  );
}

function PaymentTermsCell(props: { paymentTerms: PaymentTerm[] }) {
  return (
    <>
      {props.paymentTerms.map((term) => (
        <Badge
          key={term.id}
          label={term.paymentMethod.label + " " + moneyFormat(term.amount)}
          type="primary"
          flavor="outlined"
        />
      ))}
    </>
  );
}
