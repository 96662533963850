import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "../app/Routes";

interface navlinkProps {
  label: string;
  active?: boolean;
  href: string;
}

function Navlink(props: navlinkProps) {
  const history = useHistory();
  const classnames = props.active
    ? "bg-gray-900 text-white"
    : "text-gray-300 hover:bg-gray-700 hover:text-white";
  return (
    <a
      href="#"
      onClick={() => history.push(props.href)}
      className={classnames + " px-3 py-2 rounded-md text-sm font-medium"}
    >
      {props.label}
    </a>
  );
}

export function Navigation() {
  const location = useLocation();
  return (
    <nav className="bg-gray-800 fixed min-w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img
                className="h-8 w-8"
                src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
                alt="Workflow"
              />
            </div>
            <div>
              <div className="ml-10 flex items-baseline space-x-4">
                {[
                  { route: Routes.sales, label: "Ventes" },
                  { route: Routes.contacts, label: "Contacts" },
                ].map((link) => {
                  return (
                    <Navlink
                      label={link.label}
                      active={location.pathname === link.route}
                      href={link.route}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
