import { PaginationNav } from "./Pagination";
import React, { useEffect, useState } from "react";

function TableColumnHeader(props: { label: string }) {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
    >
      {props.label}
    </th>
  );
}

interface tableColumn<T> {
  label: string;
  content: keyof T | ((item: T) => JSX.Element | string);
}

export interface TableProps<T> {
  data: T[];
  columns: tableColumn<T>[];
}

export function Table<T>(props: TableProps<T>) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <TableBase<T> data={props.data} columns={props.columns} />
          </div>
        </div>
      </div>
    </div>
  );
}

function TableBase<T>(props: TableProps<T>) {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          {props.columns.map((h) => (
            <TableColumnHeader label={h.label} />
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {props.data.map((item, k) => {
          return (
            <tr key={k}>
              {props.columns.map((h, k) => {
                return (
                  <td className="px-6 py-4" key={k}>
                    {typeof h.content === "function"
                      ? h.content(item)
                      : item[h.content]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function InMemoryPaginatedTable<T>(props: PaginatedTableProps<T>) {
  const [currentPage, setCurrentPage] = useState<number>(props.page || 1);
  const itemsPerPage = props.itemsPerPage || 10;
  const startNumber = (currentPage - 1) * itemsPerPage + 1;

  const endNumber = currentPage * itemsPerPage;
  const [data, setData] = useState<T[]>(props.data);

  function onPageChange(page: number) {
    setCurrentPage(page);
  }
  useEffect(() => {
    setData(props.data.slice(startNumber - 1, endNumber));
  }, [currentPage]);

  return (
    <PaginatedTable
      page={currentPage}
      itemsPerPage={itemsPerPage}
      totalItemsCount={props.totalItemsCount}
      data={data}
      columns={props.columns}
      onPageChange={onPageChange}
    />
  );
}

export interface PaginatedTableProps<T> extends TableProps<T> {
  itemsPerPage?: number;
  totalItemsCount: number;
  page?: number;
  onPageChange: (newPage: number) => void;
}

export function PaginatedTable<T>(props: PaginatedTableProps<T>) {
  const itemsPerPage = props.itemsPerPage || 10;
  const startNumber = ((props.page || 1) - 1) * itemsPerPage + 1;
  const endNumber = (props.page || 1) * itemsPerPage;

  function navigateTo(page: number) {
    props.onPageChange(page);
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <TableBase<T> columns={props.columns} data={props.data} />
            <div
              className="bg-white px-4 py-3 flex items-center justify-between border-t
                              border-gray-200 sm:px-6"
            >
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Affichage des résultats de{" "}
                    <span className="font-medium">{startNumber} </span> à{" "}
                    <span className="font-medium">
                      {Math.min(endNumber, props.totalItemsCount)}{" "}
                    </span>{" "}
                    sur{" "}
                    <span className="font-medium">
                      {" "}
                      {props.totalItemsCount}{" "}
                    </span>
                  </p>
                </div>
                {props.totalItemsCount > itemsPerPage && (
                  <PaginationNav
                    current={props.page}
                    itemsPerPage={itemsPerPage}
                    totalItemsCount={props.totalItemsCount}
                    onNavigate={(page) => {
                      navigateTo(page);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
