import { InputField } from "./form/Input";
import React from "@storybook/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Chargement } from "./Chargement";
import { UI_Size } from "./definitions";

export interface AutocompleteHeader {
  node: ReactNode;
  onSelect: (currentSearch: string) => void;
}

export interface AutocompleteProps {
  placeholder: string;
  results: AutocompleteResult[];
  onSearch: (q: string) => void;
  onChooseResult: (r: any, query: string) => void;
  minQueryLength: number;
  waitsResultsUpdate?: boolean;
  header?: AutocompleteHeader;
  alwaysDisplayHeader?: boolean;
  typingTimeout?: number;
  hint?: ReactNode;
  loadingLabel?: string;
  size?: UI_Size;
}

export function Autocomplete(props: AutocompleteProps) {
  const [query, setQuery] = useState("");
  const [resultsVisible, setResultsVisible] = useState(false);
  const [selectedResultIdx, setSelectedResultIdx] = useState<number>(0);
  const [typingTimeout, setTypingTimeout] = useState<number>(0);
  const [userIsTyping, setUserIsTyping] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mouseHoverEnabled, setMouseHoverEnabled] = useState(true);

  const nbItemsInList = props.results?.length + (props.header ? 1 : 0);
  const queryExistsButIsTooShort =
    query.length < props.minQueryLength && query.length > 0;

  function selectNextResult() {
    if (selectedResultIdx >= nbItemsInList - 1) {
      setSelectedResultIdx(0);
    } else {
      setSelectedResultIdx(selectedResultIdx + 1);
    }
  }

  function selectPreviousResult() {
    if (selectedResultIdx <= 0) {
      setSelectedResultIdx(nbItemsInList - 1);
    } else {
      setSelectedResultIdx(selectedResultIdx - 1);
    }
  }
  useEffect(() => {
    setSelectedResultIdx(-1);
    if (!props.waitsResultsUpdate) {
      setIsLoading(false);
    }
  }, [props.results, props.waitsResultsUpdate]);

  function triggerSearch(q: string) {
    if (q.length >= props.minQueryLength) {
      clearTimeout(typingTimeout);
      setUserIsTyping(false);
      setIsLoading(true);
      props.onSearch(q);
      setResultsVisible(true);
    }
  }

  async function onChange(e: any) {
    setUserIsTyping(true);
    const q = e.target.value;
    setQuery(q);
    clearTimeout(typingTimeout);
    setTypingTimeout(
      window.setTimeout(() => {
        triggerSearch(q);
      }, props.typingTimeout || 700)
    );
    setIsLoading(false);
  }

  function validateCurrentResult() {
    if (selectedResultIdx === 0 && props.header) {
      props.header.onSelect(query);
      hideResults();
      return;
    }
    onChooseResult(
      props.results[selectedResultIdx - (props.header ? 1 : 0)]?.item || 0
    );
    hideResults();
  }

  function hideResults() {
    setTimeout(() => setResultsVisible(false), 100);
  }

  function onChooseResult(item: {}) {
    props.onChooseResult(item, query);
  }
  const resultRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resultRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }, [selectedResultIdx]);
  return (
    <div
      className="relative mb-2"
      onMouseMove={() => {
        setSelectedResultIdx(0);
        setMouseHoverEnabled(true);
      }}
      onKeyUp={(e: any) => {
        setMouseHoverEnabled(false);
        if (e.target.value.length >= props.minQueryLength) {
          if (e.code === "ArrowDown") {
            setResultsVisible(true);
            if (resultsVisible) {
              selectNextResult();
            }
          }
          if (e.code === "ArrowUp") {
            setMouseHoverEnabled(false);
            setResultsVisible(true);
            if (resultsVisible) {
              selectPreviousResult();
            }
          }
          if (e.code === "Enter") {
            setMouseHoverEnabled(true);
            if (resultsVisible) {
              validateCurrentResult();
            } else {
              setResultsVisible(true);
            }
          }
        }
      }}
    >
      <InputField
        id={"autocomplete"}
        name={"autocomplete"}
        value={query}
        size={props.size}
        isValid={true}
        placeholder={props.placeholder}
        onChange={onChange}
        onFocus={() => {
          setResultsVisible(true);
        }}
        onBlur={() => {
          hideResults();
        }}
        prefix={"🔎"}
        className={" bg-gray-200 text-gray-400 focus:bg-white focus:text-black"}
      />
      <div className="transition-opacity absolute w-full md:left-0.5 z-50 md:w-11/12 bg-white opacity-95 shadow-2xl max-h-96 overflow-scroll mt-0.5">
        {queryExistsButIsTooShort && props.hint}

        <div
          className={
            0 === selectedResultIdx && !mouseHoverEnabled
              ? "bg-blue-200"
              : mouseHoverEnabled
              ? "hover:bg-blue-100"
              : ""
          }
        >
          {resultsVisible &&
            (query.length >= props.minQueryLength ||
              props.alwaysDisplayHeader) &&
            props.header?.node}
        </div>
        {query.length > 0 &&
          query.length >= props.minQueryLength &&
          props.results.length === 0 &&
          resultsVisible && (
            <div className="p-2">
              {props.waitsResultsUpdate || userIsTyping ? (
                <Chargement label={props.loadingLabel} />
              ) : (
                <span>
                  Aucun résultat pour « <strong>{query}</strong> »
                </span>
              )}
            </div>
          )}
        {resultsVisible &&
          query.length >= props.minQueryLength &&
          props.results.length > 0 && (
            <>
              {isLoading && props.waitsResultsUpdate && (
                <div className="absolute right-2 top-2">
                  <Chargement label={props.loadingLabel} />
                </div>
              )}
              {props.results.map((result, k) => {
                const resultIsSelected =
                  k + (props.header ? 1 : 0) === selectedResultIdx;
                return (
                  <div
                    ref={resultIsSelected ? resultRef : null}
                    className={
                      resultIsSelected
                        ? "bg-blue-200"
                        : mouseHoverEnabled
                        ? "hover:bg-blue-100"
                        : ""
                    }
                    onMouseDown={() => onChooseResult(result.item)}
                    key={k}
                  >
                    {result.node}
                  </div>
                );
              })}
            </>
          )}
      </div>
    </div>
  );
}

export interface AutocompleteResult {
  node: ReactNode;
  item: {};
}
