import React from "@storybook/react";

export function SpinnerLoading() {
  return (
    <div
      style={{
        borderRadius: 100,
        border: "2px solid #2e5495",
        position: "relative",
        overflow: "hidden",
        height: 20,
        width: 20,
      }}
    >
      <span
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "5px",
        }}
      >
        <svg
          className="animate-waveFront "
          width="38px"
          height="24px"
          viewBox="0 0 38 24"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M38,3.12075644 L37.9767868,24 L0,24 L0.0232131949,3.12075644 C8.45689681,7.28176503 14.7824924,7.28176503 19,3.12075644 C23.2175076,-1.04025215 29.5508409,-1.04025215 38,3.12075644 Z"
              id="Rectangle"
              fill="#4f75B7"
            />
          </g>
        </svg>
        <svg
          className="animate-waveFront"
          width="38px"
          height="24px"
          viewBox="0 0 38 24"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M38,3.12075644 L37.9767868,24 L0,24 L0.0232131949,3.12075644 C8.45689681,7.28176503 14.7824924,7.28176503 19,3.12075644 C23.2175076,-1.04025215 29.5508409,-1.04025215 38,3.12075644 Z"
              id="Rectangle"
              fill="#4f75B7"
            />
          </g>
        </svg>
      </span>
      <span
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          top: "10px",
          left: "5px",
        }}
      >
        <svg
          className={"animate-waveFront"}
          width="38px"
          height="24px"
          viewBox="0 0 38 24"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M38,3.12075644 L37.9767868,24 L0,24 L0.0232131949,3.12075644 C8.45689681,7.28176503 14.7824924,7.28176503 19,3.12075644 C23.2175076,-1.04025215 29.5508409,-1.04025215 38,3.12075644 Z"
              id="Rectangle"
              fill="#2e5495"
            />
          </g>
        </svg>
        <svg
          className={"animate-waveFront"}
          width="38px"
          height="24px"
          viewBox="0 0 38 24"
        >
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M38,3.12075644 L37.9767868,24 L0,24 L0.0232131949,3.12075644 C8.45689681,7.28176503 14.7824924,7.28176503 19,3.12075644 C23.2175076,-1.04025215 29.5508409,-1.04025215 38,3.12075644 Z"
              id="Rectangle"
              fill="#2e5495"
            />
          </g>
        </svg>
      </span>
    </div>
  );
}
