import React from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "./Routes";
import { ContactsList } from "./ContactsList";
import { ContactsDirectoryAPI } from "./ContactsDirectoryAPI";

function FeatureNotReady() {
  return (
    <div className="min-h-screen flex flex-col justify-center">
      <div className="text-center mb-2">
        <p>Opopopo</p>
        <p>On n'est pas prêts !</p>
      </div>
    </div>
  );
}

function ContactForm() {
  return <FeatureNotReady />;
}

export function ContactsModule() {
  return (
    <Switch>
      <Route path={Routes.add} exact>
        <ContactForm />
      </Route>
      <Route path={Routes.contacts} exact>
        <ContactsList contactsDirectory={ContactsDirectoryAPI} />
      </Route>
    </Switch>
  );
}
