import React from "react";
import { UI_Size } from "./definitions";

type buttonType = "primary" | "secondary";
type buttonSize = UI_Size;

export interface ButtonProps {
  label: string;
  onClick: (e: any) => void;
  type?: buttonType;
  size?: buttonSize;
  disabled?: boolean;
}

export function Button(props: ButtonProps) {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={
        " flex-grow-0 inline-flex items-center justify-center border border-transparent font-medium rounded-md text-white " +
        (props.type === "secondary"
          ? "bg-gray-600 hover:bg-gray-700"
          : "bg-indigo-600 hover:bg-indigo-700") +
        (props.size === "small"
          ? " text-sm px-2 py-1"
          : props.size === "tiny"
          ? " text-xs px-1 py-1 "
          : " text-base px-5 py-3") +
        (props.disabled
          ? " disabled:opacity-50  bg-gray-600 hover:bg-gray-600 "
          : "")
      }
    >
      {props.label}
    </button>
  );
}
