import { SalesList } from "./SalesList";
import React from "react";
import { Route, Switch } from "react-router-dom";
import Routes from "./Routes";
import { SalesForm } from "./SalesForm";
import { salesBook } from "./Sales";
import { catalogue } from "./Catalogue/CatalogueAPI";
import { CartService } from "./Cart/CartService";

export function SalesModule() {
  return (
    <Switch>
      <Route path={Routes.add} exact>
        <SalesForm catalogue={catalogue} cartService={new CartService()} />
      </Route>
      <Route path={Routes.sales} exact>
        <SalesList salesBook={salesBook} />
      </Route>
    </Switch>
  );
}
