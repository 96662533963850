import { Contact, ContactId } from "./Contact";
import { ContactsDirectoryI } from "../Sales/CustomersDirectory";

export interface ContactsDirectoryStats {
  totalContactsCount: number;
}

export const ContactsDirectoryAPI: ContactsDirectoryI = {
  getOneById: (id: ContactId) => {
    return fetch("/api/contacts/" + id)
      .then((response) => response.json())
      .then((contactData) => contactData);
  },
  findAll: () => {
    return fetch("/api/contacts/")
      .then((response) => response.json())
      .then((contactsData) => contactsData);
  },
  findPaginated: (
    firstIdx: number,
    contactsPerPage: number
  ): Promise<Contact[]> => {
    return fetch("/api/contacts/")
      .then((response) => response.json())
      .then((contactsData) => {
        return contactsData.slice(firstIdx - 1, firstIdx * contactsPerPage);
      });
  },

  getStats: (): Promise<ContactsDirectoryStats> => {
    return fetch("/api/stats/contacts")
      .then((response) => response.json())
      .then((contactsStats) => {
        return contactsStats;
      });
  },

  render(item: Contact): React.ReactNode {
    return undefined;
  },

  search: (q: string): Promise<Contact[]> => {
    return fetch("/api/search/contacts/?q=" + q.trim())
      .then((response) => response.json())
      .then((contactsData) => contactsData);
  },
  add: (contact: Contact) => {
    fetch("/api/contacts/", {
      method: "POST",
      body: JSON.stringify(contact),
    })
      .then((response) => response.json())
      .then((contactData) => contactData);
  },
};
