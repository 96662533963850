import { Product, ProductId } from "../Catalogue/product";
import { Money } from "../../utilities/Money";
import { Contact, ContactId } from "../../Contacts/Contact";

export type CartItemId = string;

export interface CartItem {
  product: Product;
  id: CartItemId;
  recipient?: Contact;
}

export interface CartServiceI {
  cartItemIsValid(i: CartItem): boolean;
  cartItemIsCustomizable(i: CartItem): boolean;
  removeProductFrom(cart: Cart, id: CartItemId): Cart;
  assignContactTo(cart: Cart, c: Contact, item: CartItem): Cart;
  affectRecipientTo(cart: Cart, recipient: Contact, item: CartItem): Cart;
  unsetContactFrom(cart: Cart, item: CartItem): Cart;
  contactIsRecipientInCart(contact: Contact, cart: Cart): boolean;
  extractPurchasesFrom(cart: Cart): Purchase[];
  totalPrice(cart: Cart): Money;
  addProductTo(p: Product, cart: Cart): Cart;
  getAllRecipientsIn(cart: Cart): Contact[];
  isFirstContactOccurenceInCart(cartItem: CartItem, cart: Cart): boolean;
  cartIsValid(cart: Cart): boolean;
}

export interface Cart {
  items: CartItem[];
  customer?: Contact;
}

export function mergeContactData(c1: Contact, c2: Contact): Contact {
  return {
    birthdate: c1.birthdate || c2.birthdate,
    email: c1.email || c2.email,
    firstname: c1.firstname || c2.firstname,
    gender: c1.gender || c2.gender,
    id: c1.id,
    isNew: c1.isNew || c2.isNew,
    lastname: c1.lastname || c2.lastname,
    phone: c1.phone || c2.phone,
    phone2: c1.phone2 || c2.phone2,
  };
}

interface PurchaseCustomization {
  recipientId?: ContactId;
  recipientName?: string;
}

type PurchaseId = string;

export interface Purchase {
  id: PurchaseId;
  label: string;
  productId: ProductId;
  customization?: PurchaseCustomization;
  quantity: number;
  unitPrice: Money;
}

export const EMPTY_CART: Cart = {
  items: [],
  customer: undefined,
};
