import React, { useState } from "react";
import { Button } from "./UIKit/Button";
import { Input } from "./UIKit/form/Input";

export function LoginForm(props: {
  onSubmit: (login: string, password: string) => {};
}) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [awaitsValidation, setAwaitsValidation] = useState(true);
  function onSubmit(e: any) {
    e.preventDefault();
    setAwaitsValidation(false);
    props.onSubmit(login, password);
  }
  function onLoginChange(e: any) {
    setLogin(e.target.value);
  }
  function onPasswordChange(e: any) {
    setPassword(e.target.value);
  }
  return (
    <form
      className="flex flex-col w-full px-2 sm:max-w-2xl"
      onSubmit={onSubmit}
    >
      <h1 className="text-xl font-bold">Connexion</h1>
      <Input
        label="Identifiant"
        testid="login"
        name="login"
        isValid={login !== ""}
        awaitsValidation={awaitsValidation}
        value={login}
        onChange={onLoginChange}
      />
      <Input
        label="Mot de passe"
        type="password"
        testid="password"
        isValid={password !== ""}
        awaitsValidation={awaitsValidation}
        name="password"
        onChange={onPasswordChange}
      />
      <div className="mt-3">
        <Button label="C'est parti !" onClick={onSubmit} />
      </div>
    </form>
  );
}
