import React, { createContext, useContext, useState } from "react";

const authContext = createContext<any>({});

export function useAuth() {
  return useContext(authContext);
}
interface User {
  login: string;
}

function useProvideAuth() {
  const [user, setUser] = useState<User | false>(false);

  async function authenticate(login: string, passsword: string) {
    if (login === "roger" || login === "sylvain") {
      setUser({ login: login });
      return Promise.resolve(user);
    }
    return false;
  }

  return {
    user,
    authenticate,
  };
}

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
