import React, { useEffect, useState } from "react";
import { Input } from "../../UIKit/form/Input";
import {
  mandatoryFieldLabel,
  validableFormWidgetProps,
} from "../../UIKit/form/forms";
import { InputEmail } from "../../UIKit/form/InputEmail";
import { Contact } from "../Contacts/Contact";
import { InputGender } from "../../UIKit/form/InputGender";
import { InputDate } from "../../UIKit/form/InputDate";

type CustomerField =
  | "gender"
  | "firstname"
  | "lastname"
  | "phone2"
  | "phone"
  | "birthdate"
  | "email";

interface CustomerEditFormProps extends validableFormWidgetProps {
  onDataChange: (c: Contact) => void;
  contact: Contact;
  disabledFields: CustomerField[];
}

export function CustomerEditForm(props: CustomerEditFormProps) {
  const [gender, setGender] = useState(props.contact.gender);
  const [firstname, setFirstname] = useState(props.contact.firstname);
  const [lastname, setLastname] = useState(props.contact.lastname);
  const [phone, setPhone] = useState(props.contact.phone);
  const [phone2, setPhone2] = useState(props.contact.phone2);
  const [email, setEmail] = useState(props.contact.email);
  const [birthdate, setBirthdate] = useState(props.contact.birthdate);
  useEffect(() => {
    setLastname(props.contact.lastname || "");
    setFirstname(props.contact.firstname || "");
    setPhone(props.contact.phone || "");
    setPhone2(props.contact.phone2 || "");
    setEmail(props.contact.email || "");
    setBirthdate(props.contact.birthdate);
    setGender(props.contact.gender || "none");
    setBirthdate(props.contact.birthdate);
  }, [props.contact]);

  useEffect(() => {
    props.onDataChange({
      ...props.contact,
      gender: gender,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      phone2: phone2,
      birthdate: birthdate,
    });
  }, [firstname, lastname, email, phone, gender, birthdate]);
  return (
    <div>
      <InputGender
        isValid={props.isValid || gender !== undefined}
        awaitsValidation={props.awaitsValidation}
        disabled={props.disabledFields.includes("gender")}
        required={true}
        label="Genre"
        onChoose={(g) => {
          setGender(g);
        }}
        name={Math.random().toString()}
        value={gender}
      />
      <Input
        isValid={props.isValid || lastname !== ""}
        awaitsValidation={props.awaitsValidation}
        disabled={props.disabledFields.includes("lastname")}
        name="lastname"
        required={true}
        label="Nom"
        onChange={(e) => {
          setLastname(e.target.value);
        }}
        value={lastname}
      />
      <Input
        name="firstname"
        label="Prénom"
        required={true}
        disabled={props.disabledFields.includes("firstname")}
        onChange={(e) => setFirstname(e.target.value)}
        value={firstname}
        isValid={props.isValid || firstname !== ""}
        awaitsValidation={props.awaitsValidation}
      />
      <InputEmail
        email={email || ""}
        name="email"
        label="email"
        disabled={props.disabledFields.includes("email")}
        isValid={props.isValid}
        required={true}
        validationMessage={
          email === "" ? mandatoryFieldLabel : "cet email n'est pas valide"
        }
        awaitsValidation={props.awaitsValidation}
        onChange={setEmail}
      />
      <Input
        name="phone"
        label="téléphone principal"
        required={true}
        disabled={props.disabledFields.includes("phone")}
        value={phone}
        isValid={props.isValid || phone !== ""}
        awaitsValidation={props.awaitsValidation}
        onChange={(e) => setPhone(e.target.value)}
      />
      <InputDate
        onChange={(d) => setBirthdate(d)}
        name="birthdate"
        label="Date de naissance"
        required={false}
        disabled={props.disabledFields.includes("birthdate")}
        date={birthdate}
        isValid={props.isValid || birthdate !== undefined}
        awaitsValidation={props.awaitsValidation}
      />
      <Input
        name="phoneAlt"
        label="téléphone secondaire"
        disabled={props.disabledFields.includes("phone2")}
        isValid={true}
        value={phone2}
        awaitsValidation={props.awaitsValidation}
        onChange={(e) => setPhone2(e.target.value)}
      />
    </div>
  );
}
