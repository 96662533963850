import { emailIsValid } from "../../UIKit/form/InputEmail";
import { Gender } from "../utilities/types";
import { DateTime } from "luxon";

export interface Contact {
  id: ContactId;
  phone2?: string;
  email?: string;
  firstname?: string;
  lastname?: string;
  gender?: Gender;
  birthdate?: DateTime;
  phone?: string;
  isNew?: boolean;
}

export const EMPTY_CONTACT: Contact = {
  id: "",
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
};

export function contactIsValidAsCustomer(contact: Contact) {
  return (
    contact.firstname !== "" &&
    contact.phone !== "" &&
    contact.phone !== "" &&
    emailIsValid(contact.email)
  );
}

export type ContactId = string;

function genderLabel(gender: Gender) {
  const labels = {
    mrs: "Mme. ",
    mr: "M. ",
    none: "",
  };
  return labels[gender];
}

export function contactLabel(contact: Contact): string {
  return (
    (contact.gender && contact.gender !== "none"
      ? genderLabel(contact.gender)
      : "") +
    contact.firstname +
    " " +
    contact.lastname
  );
}
