import { Money, MONEY_ZERO, moneyAdd, moneyEquals } from "../utilities/Money";
import { v4 } from "uuid";

export type PaymentMethodSlug =
  | "CHEQUE"
  | "CARD"
  | "CASH"
  | "NONE"
  | "OTHER"
  | "";
export const PAYMENT_METHOD_NO: PaymentMethod = {
  method: "NONE",
  label: "Pas de paiement",
};
export const PAYMENT_METHOD_CHEQUE: PaymentMethod = {
  method: "CHEQUE",
  label: "Chèque bancaire",
};
export const PAYMENT_METHOD_OTHER: PaymentMethod = {
  method: "OTHER",
  label: "Autre moyen",
};
export const PAYMENT_METHOD_CARD: PaymentMethod = {
  method: "CARD",
  label: "Carte bancaire",
};
export const PAYMENT_METHOD_UNDEFINED: PaymentMethod = {
  method: "",
  label: "",
};
const PAYMENT_METHOD_CASH: PaymentMethod = {
  label: "Espèces",
  method: "CASH",
};

export interface PaymentMethod {
  method: PaymentMethodSlug;
  label: string;
}

export const paymentMethods: PaymentMethod[] = [
  PAYMENT_METHOD_CHEQUE,
  PAYMENT_METHOD_CARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_OTHER,
  PAYMENT_METHOD_NO,
];

export function paymentMethodForge(m: string): PaymentMethod {
  const meth = paymentMethods.find((method) => {
    return m === method.method;
  });
  return meth || PAYMENT_METHOD_UNDEFINED;
}

export interface PaymentTerm {
  id: string;
  paymentMethod: PaymentMethod;
  amount: Money;
  reference?: string;
}

export function paymentTermForge(
  method: PaymentMethod,
  amount: Money
): PaymentTerm {
  return { amount: amount, id: v4(), paymentMethod: method };
}

export const PAYMENT_TERM_UNDEFINED = {
  id: v4(),
  amount: MONEY_ZERO,
  paymentMethod: PAYMENT_METHOD_UNDEFINED,
};

function termsTotalEquals(terms: PaymentTerm[], target: Money): boolean {
  const termsTotal = terms.reduce<Money>((acc, curr) => {
    return moneyAdd(acc, curr.amount);
  }, MONEY_ZERO);
  return moneyEquals(termsTotal, target);
}

export function paymentTermsAreValid(
  paymentTerms: PaymentTerm[],
  total: Money
) {
  return (
    termsTotalEquals(paymentTerms, total) &&
    paymentTerms.reduce<boolean>((acc, current) => {
      return (
        current.amount.amount > 0 &&
        current.paymentMethod !== PAYMENT_METHOD_UNDEFINED &&
        (current.paymentMethod !== PAYMENT_METHOD_OTHER ||
          (current.paymentMethod === PAYMENT_METHOD_OTHER &&
            !!current.reference)) &&
        acc
      );
    }, true)
  );
}
