import { Contact } from "../Contacts/Contact";
import React from "react";

export function ContactSummary(props: { contact: Contact }) {
  return (
    <div>
      <p className="text-black">
        {props.contact.firstname}{" "}
        <span className="font-bold">{props.contact.lastname}</span>
      </p>
      <p className="text-sm">{props.contact.email}</p>
    </div>
  );
}
