import { DateTime } from "luxon";
import { Contact, ContactId } from "../Contacts/Contact";
import { Money, MONEY_ZERO, moneyAdd } from "../utilities/Money";
import { Purchase } from "./Cart/Cart";
import { PaymentTerm } from "./PaymentMethod";
import { Product, ProductData } from "./Catalogue/product";
import { MirageSalesBook } from "./APISalesBook";

type PaymentMethod = string;

export type Sale = {
  purchases: Purchase[];
  customer: Contact;
  date: DateTime;
  paymentTerms: PaymentTerm[];
  amount: Money;
};

export type SaleData = {
  productData: ProductData;
  customerId: ContactId;
  customerData: {
    customerId: ContactId;
    label: string;
  };
  date: string;
  paymentMethod: PaymentMethod;
  amount: Money;
};

export interface SalesBookI {
  getAll: () => Promise<Sale[]>;
  add: (s: Sale) => void;
}

export const salesBook: SalesBookI = MirageSalesBook;

export function totalPrice(ps: Product[]) {
  return ps.reduce<Money>((total, p) => {
    return moneyAdd(total, p.unitPrice);
  }, MONEY_ZERO);
}
