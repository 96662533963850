import { Product, ProductId } from "./product";
import { CatalogueI } from "./Catalogue";

export const catalogue: CatalogueI = {
  getOneById: (id: ProductId) => {
    return fetch("/api/products/" + id)
      .then((response) => response.json())
      .then((productData) => productData);
  },
  getAllProducts: () => {
    return fetch("/api/products/")
      .then((response) => response.json())
      .then((productsData) => productsData);
  },
  search: (q: string): Promise<Product[]> => {
    return fetch("/api/search/products/?q=" + q.trim())
      .then((response) => response.json())
      .then((productsData) => productsData);
  },
};
