import React from "react";
import { ErrorBoundary } from "./ErrorBoundary";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { LoginPage } from "./LoginPage";
import { ProvideAuth, useAuth } from "./auth";
import { NotFound } from "./UIKit/NotFound";
import { Routes } from "./app/Routes";
import { SalesModule } from "./modules/Sales/SalesUI";

import { QueryClient, QueryClientProvider } from "react-query";
import { Navigation } from "./UIKit/Navigation";
import { ContactsModule } from "./modules/Contacts/ContactsUI";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <ProvideAuth>
          <Router>
            <Switch>
              <Route path={Routes.login}>
                <LoginPage />
              </Route>
              <Route path={Routes.public}>
                <p>public page</p>
              </Route>
              <PrivateRoute path={Routes.sales}>
                <SalesModule />
              </PrivateRoute>
              <PrivateRoute path={Routes.contacts}>
                <ContactsModule />
              </PrivateRoute>
              <Route path={Routes.home} exact>
                <Redirect to={Routes.sales} />
              </Route>
              <Route path={Routes.home}>
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </ProvideAuth>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

function PrivateRoute(props: { children: JSX.Element; path: string }) {
  let auth = useAuth();
  return (
    <Route
      path={props.path}
      render={({ location }) =>
        auth.user ? (
          <div className="relative">
            <Navigation />
            <div className="container mx-auto flex flex-col min-h-screen pt-20">
              {props.children}
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: Routes.login,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
