import React from "react";
import { Hr } from "../Hr";

export type SectionProps = React.PropsWithChildren<{
  legend: string;
  title: string;
  final?: boolean;
}>;

export const FormSection: React.FC<SectionProps> = (props) => (
  <div>
    <div
      className={
        "md:grid md:grid-cols-3 md:gap-6 p-4 rounded" +
        (props.final ? " pt-0" : " pt-0 sm:pt-4 bg-gray-100")
      }
    >
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {props.title}
          </h3>
          <p className="mt-1 text-gray-600">{props.legend}</p>
        </div>
      </div>
      <div
        className={
          " md:col-span-2 px-4 sm:px-0 md:mt-0" + (props.final ? " mt-4" : "")
        }
      >
        {props.children}
      </div>
    </div>
    {props.final || <Hr />}
  </div>
);

type LegendProps = React.PropsWithChildren<{ legend: string }>;
export const Fieldset: React.FC<LegendProps> = (props) => (
  <fieldset className="border rounded px-4">
    <legend className="uppercase text-xs px-2 text-gray-400">
      {props.legend}
    </legend>
    {props.children}
  </fieldset>
);
