import { useHistory } from "react-router-dom";
import React from "react";
import { Routes } from "../app/Routes";
import { Button } from "./Button";

interface navButtonProps {
  route: string;
  label: string;
}

function NavButton(props: navButtonProps) {
  const history = useHistory();
  return (
    <Button
      onClick={() => {
        history.push(props.route);
      }}
      label={props.label}
    />
  );
}

export function NotFound() {
  return (
    <div className="mx-auto flex-col flex items-center min-h-screen justify-center">
      <p>¯\_(ツ)_/¯</p>
      <p>Il semblerait qu'on se soit perdus.</p>
      <NavButton label="revenir à l'accueil" route={Routes.home} />
    </div>
  );
}
