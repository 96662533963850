import React from "react";

type BadgeType = "primary";

export interface BadgeProps {
  label: string;
  type?: BadgeType;
  flavor?: "outlined" | "light" | "filled";
}

export function Badge(props: BadgeProps) {
  let classes = "";
  switch (props.flavor) {
    case "filled":
      classes += " bg-blue-600 text-white border border-blue-600 ";
      break;
    case "outlined":
      classes += " text-blue-600 border border-blue-600 ";
      break;
    case "light":
      classes += " bg-blue-100 text-blue-600 border border-blue-100 ";
      break;
  }
  return (
    <span
      className={
        " px-2 text-xs leading-5 font-semibold rounded-full inline-flex ml-2 whitespace-nowrap " +
        classes
      }
    >
      {props.label}
    </span>
  );
}

Badge.defaultProps = { flavor: "filled", type: "primary" };
